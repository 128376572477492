import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { HappyfolioPayload, HappyfolioState, Toc } from '#types/happyfolio';
import { uploadFile } from '#lib/api/file';
import { loadEditHappyfolioAPI, loadEditHappyfolioForAdminAPI } from '#lib/api/happyfolio';

import { sendErrorToSentry } from '#lib/error';
import { coreActions } from '../core';
import palette from '@miniintern/styles/palette';

type RegisterHappyfolioState = {
  happyfolio: HappyfolioState;
  loading: boolean;
};

const initialState: RegisterHappyfolioState = {
  happyfolio: {
    id: null,
    title: '',
    categories: [],
    hostShortBio: '',
    hostHistories: [''],
    summary:
      '<p>매력 있는 요약으로 해피폴리오의 판매 수익을 증가시켜 보세요.&nbsp;</p><p>😉 🥳 ✋ 🔥</p><p>(아래 내용은 예시입니다. 지우고 작성해 주세요.)&nbsp;&nbsp;</p><p><br></p><p>[스타트업에서 시작하는 서비스 기획자 커리어!] 를 이루기 위해</p><p>취업 준비를 하며 취득했던 자격증과, 참여했던 프로젝트를 먼저 보여드리려고 해요.&nbsp;</p><p>그리고 그동안의 제 활동들을 포트폴리오에 어떻게 녹여냈는지도 공개합니다😉&nbsp;</p><p>서비스 기획자라는 직무가 어떤 일을 하는지, 어떻게 준비하는지 🤨&nbsp;</p><p><br></p><p>아래와 같은 내용을 공유해드릴테니 저와 함께 하실 분 ✋ 🔥 🔥 🔥</p><p>· 내가 공부했던 자격증&nbsp;</p><p>· 참여했던 교육 프로그램 &amp; 프로젝트&nbsp;</p><p>· 경력 없이 포트폴리오 작성하는 법&nbsp;</p><p>· 보너스 : 스타트업 공고 모여있는 사이트 소개&nbsp;</p>',
    productionStory:
      '<p>해피폴리오가 어떤 목적으로, 어떤 과정을 통해 만들어졌는지 자세히 설명해 주세요.</p><p>(아래 내용은 예시입니다. 지우고 작성해 주세요.)</p><p><br></p><p>최근 서비스 기획자라는 직무, 많이 들어보셨죠? 👀&nbsp;</p><p>여러 가지로 많이 궁금하실 취업 준비생들을 위해 같은 고민🤨을 하며 취준해왔고,&nbsp;</p><p>현재 OO기업에서 주니어 기획자로 근무하고 있는 제가</p><p>서비스 기획자로 취업하기까지의 경험을 공유해드리려고 합니다. 👍👍👍</p><p><br></p><p>멘토링을 통해 여러분과 소통하며 저 또한 성장할 수 있다는 기대가 듭니다.</p><p>제 경험이 여러분들의 고민 해결에 도움이 되기를 바랍니다.</p><p>화이팅!</p>',
    selfCertifications: [
      { imagePath: '', originImagePath: '', editedImagePath: '', description: '' },
    ],
    recommendationTargets: [''],
    price: null,
    coverImage: '',
    pdf: {
      path: '',
      size: 0,
      totalPageCount: 0,
      previewImages: [],
    },
    license: 'CCL 표시 안함',
    tags: [],
    isTemp: false,
    contents: {
      topicList: [
        {
          title: '',
          subTopicList: [
            {
              title: '',
            },
            {
              title: '',
            },
          ],
        },
      ],
    },
    themeId: null,
    isAgree: false,
    coverTemplateInfo: {
      selectedCoverInputType: '템플릿 입력 방식',
      selectedCoverTemplateType: '',
      selectedTemplate: '',
      templateCoverText: {
        main: '',
        category: '',
      },
      templateCoverTextColors: {
        main: palette.white,
        category: palette.white,
      },
    },
    inputTags: '',
  },
  loading: false,
};

const registerHappyfolioSlice = createSlice({
  name: 'registerHappyfolio',
  initialState,
  //* 해피폴리오 등록 관련 리듀서
  reducers: {
    //? 해피폴리오 제목 등록
    setRegisterHappyfolioTitle(state, action: PayloadAction<string>) {
      state.happyfolio.title = action.payload;
    },

    //? pdf 파일 업로드
    setUploadedPDFPath(state, action: PayloadAction<{ url: string; size: number }>) {
      state.happyfolio.pdf.path = action.payload.url;
      state.happyfolio.pdf.size = action.payload.size;
      state.happyfolio.pdf.previewImages = [];
      state.happyfolio.pdf.totalPageCount = 0;
    },

    //? pdf 파일 총 장수
    setPDFTotalPageCount(state, action: PayloadAction<number>) {
      state.happyfolio.pdf.totalPageCount = action.payload;
    },

    //? 선택한 5장 미리보기 이미지
    setRegisterHappyfolioImage(state, action: PayloadAction<{ index: number; path: string }[]>) {
      state.happyfolio.pdf.previewImages = action.payload;
    },

    //? 해피폴리오 커버이미지 템플릿 입력 방식 선택
    changeCoverInputType(state, action: PayloadAction<'직접 입력 방식' | '템플릿 입력 방식'>) {
      state.happyfolio.coverTemplateInfo.selectedCoverInputType = action.payload;
    },

    // //? 해피폴리오 커버이미지 템플릿 타입 선택
    changeCoverType(
      state,
      action: PayloadAction<'rocket' | 'chart' | 'cloud' | 'writing' | 'data' | 'bulb'>,
    ) {
      state.happyfolio.coverTemplateInfo.selectedCoverTemplateType = action.payload;
    },

    // //? 해피폴리오 커버이미지 템플릿 선택
    changeSelectedTemplate(state, action: PayloadAction<string>) {
      state.happyfolio.coverTemplateInfo.selectedTemplate = action.payload;
    },

    //? 해피폴리오 커버이미지 카테고리 텍스트 컬러 설정
    setTemplateCoverTexts(
      state,
      action: PayloadAction<{ input: 'main' | 'category'; text: string }>,
    ) {
      state.happyfolio.coverTemplateInfo.templateCoverText[action.payload.input] =
        action.payload.text;
    },

    //? 해피폴리오 커버이미지 카테고리 텍스트 컬러 설정
    setTemplateCoverTextColors(
      state,
      action: PayloadAction<{ input: 'main' | 'category'; color: string }>,
    ) {
      state.happyfolio.coverTemplateInfo.templateCoverTextColors[action.payload.input] =
        action.payload.color;
    },

    //? 해피폴리오 커버이미지 등록
    getUploadCoverImage_Request(state, _action: PayloadAction<FormData>) {
      state.loading = true;
    },
    getUploadCoverImage_Success(state, action: PayloadAction<string>) {
      state.loading = false;
      state.happyfolio.coverImage = action.payload;
    },
    getUploadCoverImage_Failure(state, _action: PayloadAction<undefined>) {
      state.loading = false;
    },

    //? 해피폴리오 태그 등록
    setRegisterHappyfolioTags(state, action: PayloadAction<string>) {
      const removedEmptyTags = action.payload.split('#').filter(val => val.trim() !== '');
      state.happyfolio.inputTags = action.payload;
      state.happyfolio.tags = removedEmptyTags;
    },

    //? 해피폴리오 태그 등록
    deleteSharpTextInTags(state, _action: PayloadAction<undefined>) {
      state.happyfolio.inputTags = state.happyfolio.inputTags.slice(1);
    },

    //? 해피폴리오 태그 삭제
    setRemoveRegisterHappyfolioTag(state, action: PayloadAction<number>) {
      state.happyfolio.tags = state.happyfolio.tags.filter(
        (tag, index) => index !== action.payload,
      );

      state.happyfolio.inputTags = state.happyfolio.inputTags
        .split('#')
        .filter((tag, idx) => action.payload !== idx)
        .join('#');
    },

    //? 해피폴리오 등록 목차 타입 선택
    // setRegisterHappyfolioTocType(state, action: PayloadAction<'order' | 'unorder'>) {
    //   state.happyfolio.contents.type = action.payload;
    // },

    //? 해피폴리오 등록 목차 내용 작성
    setRegisterHappyfolioTocList(
      state,
      action: PayloadAction<{
        title?: string; // 확장성을 위해 옵셔널
        // page?: string; // 확장성을 위해 옵셔널
        index?: number;
        parentIndex?: number;
      }>,
    ) {
      const newTopicList = state.happyfolio.contents.topicList.map((topic, topicIndex) => {
        // 소주제 로직
        if (action.payload.parentIndex !== undefined) {
          if (action.payload.parentIndex === topicIndex) {
            if (topic.subTopicList && topic.subTopicList.length > 0) {
              const newSubTopic = topic.subTopicList.map((subTopic, subTopicIndex) => {
                if (subTopicIndex === action.payload.index) {
                  // 불필요한 요소 삭제
                  delete action.payload.parentIndex;
                  delete action.payload.index;

                  return { ...subTopic, ...action.payload };
                }
                return subTopic;
              });

              topic.subTopicList = newSubTopic;
            }
          }
        } else {
          // 대제목 로직
          if (action.payload.index === topicIndex) {
            // 불필요한 요소 삭제
            delete action.payload.parentIndex;
            delete action.payload.index;

            return { ...topic, ...action.payload };
          }
        }
        return topic;
      });

      state.happyfolio.contents.topicList = newTopicList;
    },

    //? 해피폴리오 등록 목차 소팅 기능
    setRegisterHappyfolioTocSorting(
      state,
      action: PayloadAction<{
        index: number;
        parentIndex?: number;
      }>,
    ) {
      // 소제목 > 대제목
      // 해당 요소보다 뒤에 있는 요소들은 대제목으로 바뀌는 요소의 자식으로 들어감
      if (action.payload.parentIndex !== undefined) {
        const targetParentToc = state.happyfolio.contents.topicList.find(
          (topic, index) => index === action.payload.parentIndex,
        );
        const targetToc = targetParentToc!.subTopicList!.find(
          (subTopic, index) => index === action.payload.index,
        );

        const removeTargetSubTopic = targetParentToc!.subTopicList!.filter(
          (subTopic, index) => index !== action.payload.index && index < action.payload.index,
        );

        const nextSiblingsSubTopic = targetParentToc!.subTopicList!.filter(
          (subTopic, index) => index > action.payload.index,
        );

        targetToc!.subTopicList = nextSiblingsSubTopic;
        targetParentToc!.subTopicList = removeTargetSubTopic;

        state.happyfolio.contents.topicList.splice(action.payload.parentIndex + 1, 0, targetToc!);
      } else {
        // 대제목 > 소제목
        const targetToc = state.happyfolio.contents.topicList.find(
          (topic, index) => index === action.payload.index,
        ) as Toc;

        // 바뀌는 변경될 대제목 안에 소제목이 있을 경우의 처리
        const targetTocSubTopic =
          targetToc.subTopicList !== undefined && targetToc.subTopicList.length > 0
            ? targetToc.subTopicList
            : [];

        const filterToc = state.happyfolio.contents.topicList.map((topic, index) => {
          if (index === action.payload.index - 1) {
            if (topic.subTopicList) {
              return {
                ...topic,
                subTopicList:
                  targetTocSubTopic.length > 0
                    ? [
                        ...topic.subTopicList,
                        { title: targetToc.title, subTopicList: [] },
                        ...targetTocSubTopic,
                      ]
                    : [...topic.subTopicList, targetToc],
              };
            }
            return {
              ...topic,
              subTopicList: [{ title: targetToc.title, subTopicList: [] }, ...targetTocSubTopic],
            };
          }
          return topic;
        });

        state.happyfolio.contents.topicList = filterToc;
        state.happyfolio.contents.topicList.splice(action.payload.index, 1);
      }
    },

    //? 해피폴리오 등록 목차 추가
    setAddToc(state, _action: PayloadAction<undefined>) {
      state.happyfolio.contents.topicList.push({
        title: '',
      });
    },

    //? 해피폴리오 등록 목차 삭제
    setRemoveToc(
      state,
      action: PayloadAction<{
        index: number;
        parentIndex?: number;
      }>,
    ) {
      if (action.payload.parentIndex === undefined) {
        // 대제목인 경우
        const targetToc = state.happyfolio.contents.topicList.find(
          (topic, index) => index === action.payload.index,
        ) as Toc;

        if (targetToc.subTopicList && targetToc.subTopicList.length > 0) {
          // 만약 삭제해야될 대제목내에 소제목이 포함되어 있는 경우
          const changedToc = state.happyfolio.contents.topicList.map((topic, index) => {
            if (index === action.payload.index - 1) {
              if (topic.subTopicList && topic.subTopicList.length > 0) {
                return {
                  ...topic,
                  subTopicList: [...topic.subTopicList, ...targetToc!.subTopicList!],
                };
              }
              return {
                ...topic,
                subTopicList: targetToc!.subTopicList!,
              };
            }
            return topic;
          });

          const filteredToc = changedToc.filter((topic, index) => index !== action.payload.index);
          state.happyfolio.contents.topicList = filteredToc;
        } else {
          state.happyfolio.contents.topicList = state.happyfolio.contents.topicList.filter(
            (topic, index) => index !== action.payload.index,
          );
        }
      } else {
        // 소제목일 경우
        const targetParentToc = state.happyfolio.contents.topicList.find(
          (topic, index) => index === action.payload.parentIndex,
        ) as Toc;

        const filterToc = targetParentToc!.subTopicList!.filter(
          (topic, index) => index !== action.payload.index,
        );

        targetParentToc.subTopicList = filterToc;

        state.happyfolio.contents.topicList.splice(action.payload.parentIndex, 1, targetParentToc);
      }
    },

    //? 해피폴리오 등록 카테고리 선택
    setRegisterHappyfolioCategories(state, action: PayloadAction<string[]>) {
      state.happyfolio.categories = action.payload;
    },

    //? 해피폴리오 등록 내용 요약 작성
    setRegisterHappyfolioSummary(state, action: PayloadAction<string>) {
      state.happyfolio.summary = action.payload;
    },

    //? 호스트 소개 작성
    setRegisterHappyfolioHostShortBio(state, action: PayloadAction<string>) {
      state.happyfolio.hostShortBio = action.payload;
    },

    //? 호스트 이력 및 활동 작성
    setRegisterHappyfolioHostHistories(
      state,
      action: PayloadAction<{ index: number; story: string }>,
    ) {
      state.happyfolio.hostHistories[action.payload.index] = action.payload.story;
    },

    //? 호스트 이력 및 활동 추가
    addHappyfolioHostHistories(state, _action: PayloadAction<undefined>) {
      state.happyfolio.hostHistories = [...state.happyfolio.hostHistories, ''];
    },

    //? 호스트 이력 및 활동 삭제
    removeHappyfolioHostHistories(state, action: PayloadAction<number>) {
      const removedHostHistories = state.happyfolio.hostHistories.filter(
        (story, index) => index !== action.payload,
      );
      state.happyfolio.hostHistories = removedHostHistories;
    },

    //? 셀프 인증 설명 작성
    setRegisterHappyfolioSelfCertificationDescription(
      state,
      action: PayloadAction<{ index: number; description: string }>,
    ) {
      state.happyfolio.selfCertifications[action.payload.index].description =
        action.payload.description;
    },

    //? 셀프 인증 추가
    addHappyfolioSelfCertification(state, _action: PayloadAction<undefined>) {
      state.happyfolio.selfCertifications = [
        ...state.happyfolio.selfCertifications,
        { imagePath: '', originImagePath: '', editedImagePath: '', description: '' },
      ];
    },

    //? 셀프 인증 삭제
    removeHappyfolioSelfCertification(state, action: PayloadAction<number>) {
      const removedCertification = state.happyfolio.selfCertifications.filter(
        (certification, index) => index !== action.payload,
      );
      state.happyfolio.selfCertifications = removedCertification;
    },

    //? 셀프 인증 이미지 등록
    getUploadCertificationImage_Request(
      state,
      _action: PayloadAction<{ index: number; originImagePath: string }>,
    ) {
      state.loading = true;
    },
    getUploadCertificationImage_Success(
      state,
      action: PayloadAction<{ index: number; originImagePath: string }>,
    ) {
      state.loading = false;
      state.happyfolio.selfCertifications[action.payload.index].originImagePath =
        action.payload.originImagePath;
      state.happyfolio.selfCertifications[action.payload.index].editedImagePath = '';
    },
    getUploadCertificationImage_Failure(state, _action: PayloadAction<undefined>) {
      state.loading = false;
    },

    //? 블러이미지 업로드
    setRegisterHappyfolioEditedCertificationImage(
      state,
      action: PayloadAction<{ index: number; editedImagePath: string }>,
    ) {
      state.happyfolio.selfCertifications[action.payload.index].editedImagePath =
        action.payload.editedImagePath;
    },

    //? 해피폴리오 등록 제작스토리 작성
    setRegisterHappyfolioProductionStory(state, action: PayloadAction<string>) {
      state.happyfolio.productionStory = action.payload;
    },

    //? 추천 대상 작성
    setRegisterHappyfolioRecommendationTargets(
      state,
      action: PayloadAction<{ index: number; target: string }>,
    ) {
      state.happyfolio.recommendationTargets[action.payload.index] = action.payload.target;
    },

    //? 추천 대상 추가
    addHappyfolioRecommendationTargets(state, _action: PayloadAction<undefined>) {
      state.happyfolio.recommendationTargets = [...state.happyfolio.recommendationTargets, ''];
    },

    //? 추천 대상 삭제
    removeHappyfolioRecommendationTargets(state, action: PayloadAction<number>) {
      const removedRecommendationTargets = state.happyfolio.recommendationTargets.filter(
        (story, index) => index !== action.payload,
      );
      state.happyfolio.recommendationTargets = removedRecommendationTargets;
    },

    //? 해피폴리오 등록 라이센스 선택
    setRegisterHappyfolioLicense(state, action: PayloadAction<string>) {
      state.happyfolio.license = action.payload;
    },

    //? 해피폴리오 가격 등록
    setRegisterHappyfolioPrice(state, action: PayloadAction<number | null>) {
      state.happyfolio.price = action.payload;
    },

    //? 이용약관 체크
    setRegisterPolicyAgree(state, action: PayloadAction<boolean>) {
      state.happyfolio.isAgree = action.payload;
    },

    //? reset
    resetRegisterState(state, _action: PayloadAction<undefined>) {
      state = initialState;

      return state;
    },

    //? 해피폴리오 불러오기(수정시)
    getEditHappyfolio_Request(
      state,
      _action: PayloadAction<{ id: number; mode?: string | string[] | null }>,
    ) {
      state.loading = true;
    },

    getEditHappyfolio_Success(state, action: PayloadAction<HappyfolioPayload>) {
      state.loading = false;
      const categories = action.payload.categories.length > 0 ? action.payload.categories : [];
      const tags = action.payload.tags.length > 0 ? action.payload.tags : [];

      state.happyfolio = { ...state.happyfolio, ...action.payload };
      // state.happyfolio.coverTemplateInfo.selectedCoverInputType =
      //   action.payload.coverTemplateInfo.selectedCoverInputType;
      state.happyfolio.categories = categories;
      state.happyfolio.inputTags = tags.join('#');
      state.happyfolio.tags = tags;
    },

    getEditHappyfolio_Failure(state, _action: PayloadAction<undefined>) {
      state.loading = false;
    },
  },
});

//? 해피폴리오 커버이미지 업로드
function* watchUploadCoverImage() {
  const { getUploadCoverImage_Request, getUploadCoverImage_Success, getUploadCoverImage_Failure } =
    registerHappyfolioSlice.actions;
  const { setToaster, closeAllModal } = coreActions;

  yield takeLatest(getUploadCoverImage_Request, function* (action) {
    try {
      const { data } = yield call(uploadFile, action.payload);
      yield put(getUploadCoverImage_Success(data.url));
      yield put(closeAllModal());
    } catch (err) {
      sendErrorToSentry(err);
      yield put(
        setToaster({
          visible: true,
          message: '업로드에 실패했습니다.',
          type: 'error',
        }),
      );
      yield put(getUploadCoverImage_Failure());
    }
  });
}

//? 해피폴리오 셀프 인증 업로드
function* watchUploadCertificationImage() {
  const {
    getUploadCertificationImage_Request,
    getUploadCertificationImage_Success,
    getUploadCertificationImage_Failure,
  } = registerHappyfolioSlice.actions;
  const { setToaster, closeAllModal } = coreActions;

  yield takeLatest(getUploadCertificationImage_Request, function* (action) {
    try {
      yield put(
        getUploadCertificationImage_Success({
          index: action.payload.index,
          originImagePath: action.payload.originImagePath,
        }),
      );
      yield put(closeAllModal());
    } catch (err) {
      sendErrorToSentry(err);
      yield put(
        setToaster({
          visible: true,
          message: '업로드에 실패했습니다.',
          type: 'error',
        }),
      );
      yield put(getUploadCertificationImage_Failure());
    }
  });
}

function* getEditHappyfolioRequest() {
  const { getEditHappyfolio_Request, getEditHappyfolio_Success, getEditHappyfolio_Failure } =
    registerHappyfolioSlice.actions;
  const { setToaster } = coreActions;

  yield takeLatest(getEditHappyfolio_Request, function* (action) {
    try {
      const { id, mode } = action.payload;

      const { data } = yield call(
        mode === 'admin' ? loadEditHappyfolioForAdminAPI : loadEditHappyfolioAPI,
        id,
      );

      yield put(getEditHappyfolio_Success(data));
    } catch (err) {
      sendErrorToSentry(err);
      yield put(
        setToaster({
          visible: true,
          message: '잘못된 접근입니다.',
          type: 'error',
          fn: 'toHome',
        }),
      );
      yield put(getEditHappyfolio_Failure(err));
    }
  });
}

export const happyfolioRegisterSagas = [
  fork(getEditHappyfolioRequest),
  fork(watchUploadCoverImage),
  fork(watchUploadCertificationImage),
];

export default registerHappyfolioSlice;
