import axios from './apiClient';
import { FileType } from '#types/data';
//* 파일 업로드하기
export const uploadFile = (formData: FormData) => axios.post<FileType>('file', formData);

export const uploadPortfolioAPI = (formData: FormData) =>
  axios.post('file?portfolio=true', formData);

//* 다운로드 파일 API
export const downloadFileAPI = (filepath: string) =>
  axios.get(`/api/v2/files/download?filePath=${filepath}`, { responseType: 'arraybuffer' });
