import {
  GetMyHappyfolioCommentCountAPIResponse,
  GetMyHappyfolioCommentsAPIResponse,
} from '#types/apiResponse';
import { makeUrlQueries } from '@miniintern/utils';
import {
  HappfoliosItemType,
  Happyfolio,
  HappyfolioNoticeType,
  HappyfolioPayload,
  HappyfolioPurchaseHistory,
  LikedHappyfolioType,
} from '#types/happyfolio';
import axios from './apiClient';
import { HappyfolioHomeState } from '#modules/happyfolio/home';
import { fetchWrapper } from '#lib/fetchWrapper';

export type GetHappyfolioListAPIResponse = {
  happyfolios: Happyfolio[];
  count: number;
};

export type GetHappyfolioListQueryType = {
  limit?: string | string[];
  page?: string | string[];
  category?: string | string[];
  orderBy?: string | string[];
  notInHappyfolioId?: number;
};

export const getHappyfolioListApiKey = (query: GetHappyfolioListQueryType) =>
  makeUrlQueries('/api/v2/happyfolios', query);

//* 해피폴리오 리스트 불러오기
export const getHappyfolioListAPI = (query: GetHappyfolioListQueryType) =>
  axios.get<GetHappyfolioListAPIResponse>(getHappyfolioListApiKey(query));

export const fetchHappyfolioList = async (query: GetHappyfolioListQueryType, option: RequestInit) =>
  fetchWrapper<GetHappyfolioListAPIResponse>(getHappyfolioListApiKey(query), option);

export type GetHappyfolioListThemeQueryType = {
  limit?: string | string[];
  page?: string | string[];
  themeId?: string | string[];
  category?: string | string[];
  keyword?: string | string[];
  sort?: string | string[];
};

export type GetLikedHappyfolioListAPIResponse = {
  happyfolios: LikedHappyfolioType[];
  count: number;
};

//* 관심 해피폴리오 리스트 불러오기 API
export const getLikedHappyfolioListAPI = ({ page }: { page: number }) =>
  axios.get<GetLikedHappyfolioListAPIResponse>(`/api/v2/me/happyfolios/likes?page=${page}&limit=6`);

//* 구매 해피폴리오 리스트 불러오기 API 결과
export type GetPurchasedHappyfolioListAPIResponse = {
  purchaseHistories: HappyfolioPurchaseHistory[];
  count: number;
};

//* 구매 해피폴리오 리스트 불러오기 API
export const getPurchasedHappyfolioListAPI = ({ page }: { page: number }) =>
  axios.get<GetPurchasedHappyfolioListAPIResponse>(
    `/api/v2/me/happyfolios/pay-history?page=${page}&limit=6`,
  );

export const getPurchasedHappyfolioPayInfoAPI = ({ happyfolioId }: { happyfolioId: number }) =>
  axios.get<HappyfolioPurchaseHistory['pay']>(`/api/v2/me/happyfolios/pay-history/${happyfolioId}`);

export type ReviewPurchasedHappyfolioAPIBody = {
  question: string;
  details: { keyword: string; answer: string }[];
  rating: number;
  text?: string;
};

//* 구매 해피폴리오 평가하기 API
export const reviewPurchasedHappyfolioAPI = (
  happyfolioId: number,
  body: ReviewPurchasedHappyfolioAPIBody,
) => axios.post(`/api/v2/happyfolios/${happyfolioId}/survey`, body);

//* 나의 해피폴리오 댓글 리스트 불러오기 API
export const getMyHappyfolioCommentListAPI = ({ page }: { page: number }) =>
  axios.get<GetMyHappyfolioCommentsAPIResponse>(
    `/api/v2/me/happyfolios/comments/users?limit=10&page=${page}`,
  );

//* 나의 해피폴리오 댓글 개수 불러오기 API
export const getMyHappyfolioCommentCountAPI = () =>
  axios.get<GetMyHappyfolioCommentCountAPIResponse>('/api/v2/me/happyfolios/comments/users/count');

export interface GetHappyfolioAPIType {
  happyfolioId: string;
}

export const getSingleHappyfolioAPIKey = (happyfolioId: string) =>
  `/api/v2/happyfolios/${happyfolioId}`;

export const getHappyfolioAPI = async ({ happyfolioId }: GetHappyfolioAPIType) =>
  axios.get<Happyfolio>(getSingleHappyfolioAPIKey(happyfolioId));

export const fetchHappyfolioAPI = (id: string, options?: RequestInit) =>
  fetchWrapper<Happyfolio>(getSingleHappyfolioAPIKey(id), { ...options });

export const getPreviewHappyfolioAPI = async ({ happyfolioId }: GetHappyfolioAPIType) =>
  axios.get<Happyfolio>(getSingleHappyfolioAPIKey(happyfolioId));

export interface GetHappyfolioCommentAPIType {
  happyfolioId: number;
  limit?: string;
  page?: string;
}

export const getHappyfolioCommentAPI = ({
  happyfolioId,
  limit,
  page,
}: GetHappyfolioCommentAPIType) =>
  axios.get(makeUrlQueries(`/api/v2/happyfolios/${happyfolioId}/comments`, { limit, page }));
export interface WriteHappyfolioCommentAPIType {
  body: string;
  parentId?: number;
  happyfolioId: number;
}

export const writeHappyfolioCommentAPI = ({
  body,
  parentId,
  happyfolioId,
}: WriteHappyfolioCommentAPIType) =>
  axios.post(`/api/v2/happyfolios/${happyfolioId}/comments`, { body, parentId });

export interface EditHappyfolioCommentAPIType {
  body: string;
  commentId: number;
  happyfolioId: number;
}
//? 해피폴리오 댓글 수정하기
export const editHappyfolioCommentAPI = ({
  body,
  commentId,
  happyfolioId,
}: EditHappyfolioCommentAPIType) =>
  axios.patch(`/api/v2/happyfolios/${happyfolioId}/comments/${commentId}`, { body });

export interface deleteHappyfolioCommentAPIAPIType {
  happyfolioId: number;
  commentId: number;
}

export const deleteHappyfolioCommentAPI = ({
  happyfolioId,
  commentId,
}: deleteHappyfolioCommentAPIAPIType) =>
  axios.delete(`/api/v2/happyfolios/${happyfolioId}/comments/${commentId}`);

export const likeHappyfolioAPI = (happyfolioId: number) =>
  axios.post(`api/v2/happyfolios/${happyfolioId}/likes`);

//* 좋아요 취소하기
export const deleteHappyfolioLikeAPI = (happyfolioId: number) =>
  axios.delete(`api/v2/happyfolios/${happyfolioId}/likes`);

export interface CreateHappyfolioPayHistoryAPIType {
  happyfolioId: number;
  body: {
    payValidateData: {
      productPrice: number;
      receiptId: string;
      orderId: string;
    };
  };
}

//* 해피폴리오 등록하기
export const registerHappyfolioAPI = (happyfolioInfo: HappyfolioPayload) =>
  axios.post('api/v2/me/happyfolios', happyfolioInfo);

//* 해피폴리오 수정하기
export const updateHappyfolioAPI = (id: number, happyfolioInfo: HappyfolioPayload) =>
  axios.patch(`api/v2/me/happyfolios/${id}`, happyfolioInfo);

//* 해피폴리오 수정하기(관리자모드);
export const updateHappyfolioForAdminAPI = (id: number, happyfolioInfo: HappyfolioPayload) =>
  axios.patch(`api/v2/admins/happyfolios/${id}`, happyfolioInfo);

//* 해피폴리오 불러오기(수정 시)
export const loadEditHappyfolioAPI = (id: number) => axios.get(`api/v2/me/happyfolios/${id}`);

//* 해피폴리오 불러오기(관리자모드 수정 시)
export const loadEditHappyfolioForAdminAPI = (id: number) =>
  axios.get(`api/v2/admins/happyfolios/${id}`);

export const downloadHappyfolioAPI = (happyfolioId: number) =>
  axios.get(`api/v2/happyfolios/${happyfolioId}/files`, { responseType: 'arraybuffer' });

//* 해피폴리오 현황보드 불러오기
export const getMyHappyfolioBoardListAPI = ({ page }: { page: number }) =>
  axios.get(`api/v2/me/happyfolios?page=${page}&limit=6`);

//* 해피폴리오 호스트 유저 댓글 불러오기
export const getHostUserCommentListAPI = ({ page }: { page: number }) =>
  axios.get(`api/v2/me/happyfolios/comments/hosts?limit=10&page=${page}`);

//* 해피폴리오 호스트 유저 댓글 갯수 불러오기
export const getHostUserCommentCountAPI = () =>
  axios.get('api/v2/me/happyfolios/comments/hosts/count');

//* 해피폴리오 판매 중지, 판매 재시작 상태 변경
export const changeMyhappyfolioStatusAPI = ({ id, status }: { id: number; status: string }) =>
  axios.patch(`api/v2/me/happyfolios/${id}/status`, { status });

//* 해피폴리오 정산 내역 리스트 불러오기
export const getHostUserAccountListAPI = ({
  page,
  year,
  month,
}: {
  page: number;
  year: number;
  month: number;
}) =>
  axios.get(
    `api/v2/me/happyfolios/pay-history/settlement?page=${page}&limit=10&year=${year}&month=${month}`,
  );

//* 해피폴리오 수정요청 메시지 보내기
export const sendModificationRequestMessage = (happyfolioId: number, message: string) =>
  axios.patch(`api/v2/admins/happyfolios/${happyfolioId}/fix`, { message });

//* 해피폴리오 승인하기
export const approveHappyfolioAPI = (id: string, themeId: number | null) =>
  axios.patch(`/api/v2/admins/happyfolios/${id}/approve`, { themeId });

//* 해피폴리오 테마 리스트 불러오기
export const getMainHappyfolioThemeListApiKey = () => '/api/v2/happyfolios/theme/relations';

//* 해피폴리오 테마 리스트 불러오기
export const getMainHappyfolioThemeListAPI = () =>
  axios.get<HappyfolioHomeState['themedHappyfolio'][]>(getMainHappyfolioThemeListApiKey());

export const fetchMainHappyfolioThemeListAPI = (option?: RequestInit) =>
  fetchWrapper<HappyfolioHomeState['themedHappyfolio'][]>(
    getMainHappyfolioThemeListApiKey(),
    option,
  );

//* 해피폴리오 테마별 리스트 불러오기
export const getHappyfolioThemeListAPI = (query: GetHappyfolioListThemeQueryType) => {
  const { themeId } = query;
  const copiedQuery = { ...query };
  if (copiedQuery.themeId) {
    delete copiedQuery.themeId;
  }
  return axios.get<{ count: number; happyfolios: HappfoliosItemType[] }>(
    makeUrlQueries(`/api/v2/happyfolios/theme/${themeId}/happyfolios`, copiedQuery),
  );
};

//* 테마 불러오기(어드민 preview header)
export const getHappyfolioAdminsThemesAPI = () => axios.get('/api/v2/admins/happyfolios/theme');

export type GetHappyfolioReviewsAPIQueryType = {
  happyfolioId: number;
  page: string;
  limit: string;
};

export const getHappyfolioReviewsAPI = ({
  happyfolioId,
  page,
  limit,
}: GetHappyfolioReviewsAPIQueryType) =>
  axios.get(`/api/v2/happyfolios/${happyfolioId}/survey?page=${page}&limit=${limit}`);

export interface GetHappyfolioNoticesAPIType {
  page: number;
  limit: number;
}

export const getHappyfolioNoticesApiKey = (query: GetHappyfolioNoticesAPIType) =>
  makeUrlQueries('/api/v2/happyfolios/notices', query);

//* 해피폴리오 공지사항 불러오기
export const getHappyfolioNoticesAPI = (query: GetHappyfolioNoticesAPIType) =>
  axios.get<{ notices: HappyfolioNoticeType[]; count: number }>(getHappyfolioNoticesApiKey(query));

//* 해피폴리오 공지사항 불러오기
export const fetchHappyfolioNoticesAPI = (
  query: GetHappyfolioNoticesAPIType,
  option?: RequestInit,
) =>
  fetchWrapper<{ notices: HappyfolioNoticeType[]; count: number }>(
    getHappyfolioNoticesApiKey(query),
    option,
  );

export const getHappyfolioNoticeApiKey = (happyfolioNoticeId: number) =>
  `/api/v2/happyfolios/notices/${happyfolioNoticeId}`;

export const getHappyfolioNoticeAPI = (happyfolioNoticeId: number) =>
  axios.get<HappyfolioNoticeType>(getHappyfolioNoticeApiKey(happyfolioNoticeId));

export const fetchHappyfolioNoticeAPI = (happyfolioNoticeId: number, option?: RequestInit) =>
  fetchWrapper<HappyfolioNoticeType>(getHappyfolioNoticeApiKey(happyfolioNoticeId), option);

//해피폴리오 호스트 정산 문의하기
export const sendHappyfolioHostSettlementInquiryAPI = (contents: string) =>
  axios.post('api/v2/happyfolios/hosts/qnas', { contents });

interface PayHappyfilioAPIBodyType {
  payValidateData: {
    receiptId: string;
    orderId: string;
    productPrice: number;
  };
  phone: string;
}

export const payHappfolioAPI = (happyfolioId: number, body: PayHappyfilioAPIBodyType) =>
  axios.post(`api/v3/happyfolios/${happyfolioId}/pay-history`, body);
