import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, delay, fork, put, race, takeEvery } from 'redux-saga/effects';
import { happyfolioActions } from '.';
import { GetMyHappyfolioCommentsAPIResponse } from '#types/apiResponse';
import {
  HappyfolioBoardList,
  HappyfolioPurchaseHistory,
  HostComment,
  LikedHappyfolioType,
  MyHappyfolioComment,
  SettlementList,
} from '#types/happyfolio';
import {
  GetLikedHappyfolioListAPIResponse,
  getHostUserAccountListAPI,
  getHostUserCommentListAPI,
  getLikedHappyfolioListAPI,
  getMyHappyfolioBoardListAPI,
  getMyHappyfolioCommentListAPI,
  getPurchasedHappyfolioListAPI,
  GetPurchasedHappyfolioListAPIResponse,
} from '#lib/api/happyfolio';
import { sendErrorToSentry } from '#lib/error';

export type MeHappyfolioMeState = {
  liked: {
    loading: boolean;
    list: LikedHappyfolioType[];
    count: number;
  };
  purchased: {
    loading: boolean;
    list: HappyfolioPurchaseHistory[];
    count: number;
  };
  comment: {
    loading: boolean;
    list: MyHappyfolioComment[];
    count: number | null;
  };
  board: {
    loading: boolean;
    happyfolios: HappyfolioBoardList[];
    count: number;
  };
  hostComment: {
    comments: HostComment[];
    count: number;
    loading: boolean;
  };
  accounts: {
    settlementList: SettlementList[];
    count: number;
    totalSales: number;
    totalCount: number;
    loading: boolean;
  };
};

const initialState: MeHappyfolioMeState = {
  liked: {
    loading: true,
    list: [],
    count: 0,
  },
  purchased: {
    loading: true,
    list: [],
    count: 0,
  },
  comment: {
    loading: true,
    list: [],
    count: null,
  },
  board: {
    loading: true,
    happyfolios: [],
    count: 0,
  },
  hostComment: {
    comments: [],
    count: 0,
    loading: true,
  },
  accounts: {
    settlementList: [],
    count: 0,
    totalSales: 0,
    totalCount: 0,
    loading: true,
  },
};

//* 해피폴리오 관련 리듀서
const happyfolioMeSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    //* 관심 해피폴리오 리스트 불러오기
    getLikedList_Request(state, _action: PayloadAction<{ page: number }>) {
      state.liked.loading = true;
    },
    getLikedList_Success(state, action: PayloadAction<GetLikedHappyfolioListAPIResponse>) {
      state.liked.list = action.payload.happyfolios;
      state.liked.count = action.payload.count;
      state.liked.loading = false;
    },
    getLikedList_Failure(state) {
      state.liked.loading = false;
    },
    setLikedHappyfolioIsPaid(state, action: PayloadAction<number>) {
      const targetHappyfolio = state.liked.list.find(
        happyfolio => happyfolio.id === action.payload,
      );
      if (!targetHappyfolio) return;
      targetHappyfolio.isPaid = true;
    },
    //* =================================================================================== *//
    //* 구매한 해피폴리오 리스트 불러오기
    getPurchasedList_Request(state, _action: PayloadAction<{ page: number }>) {
      state.purchased.loading = true;
    },
    getPurchasedList_Success(state, action: PayloadAction<GetPurchasedHappyfolioListAPIResponse>) {
      state.purchased.list = action.payload.purchaseHistories;
      state.purchased.count = action.payload.count;
      state.purchased.loading = false;
    },
    getPurchasedList_Failure(state) {
      state.purchased.loading = false;
    },
    //* =================================================================================== *//
    //* 해피폴리오 댓글 리스트 불러오기
    getMyHappyfolioComments_Request(state, _action: PayloadAction<{ page: number }>) {
      state.comment.loading = true;
    },
    getMyHappyfolioComments_Success(
      state,
      action: PayloadAction<GetMyHappyfolioCommentsAPIResponse>,
    ) {
      state.comment.list = action.payload.comments;
      state.comment.loading = false;
    },
    getMyHappyfolioComments_Failure(state) {
      state.comment.loading = false;
    },
    getMyHappyfolioCommentsCount_Update(state, action: PayloadAction<number>) {
      state.comment.count = action.payload;
    },

    //* 해피폴리오 현황보드 리스트 불러오기
    getMyHappyfolioBoardList_Request(state, _action: PayloadAction<number>) {
      state.board.loading = true;
    },
    getMyHappyfolioBoardList_Success(
      state,
      action: PayloadAction<{ happyfolios: HappyfolioBoardList[]; count: number }>,
    ) {
      state.board = { ...state.board, ...action.payload };
      state.board.loading = false;
    },
    getMyHappyfolioBoardList_Failure(state, _action: PayloadAction<undefined>) {
      state.board.loading = false;
    },
    //* 해피폴리오 멘토링 리스트 불러오기
    getHostUserCommentList_Request(state, _action: PayloadAction<number>) {
      state.hostComment.loading = true;
    },
    getHostUserCommentList_Success(
      state,
      action: PayloadAction<{ comments: HostComment[]; count: number }>,
    ) {
      state.hostComment.comments = action.payload.comments;
      state.hostComment.count = action.payload.count;
      state.hostComment.loading = false;
    },
    getHostUserCommentList_Failure(state, _action: PayloadAction<undefined>) {
      state.hostComment.loading = false;
    },
    //* 정산 내역 리스트 불러오기
    getHostUserAccountList_Request(
      state,
      _action: PayloadAction<{ page: number; year: number; month: number }>,
    ) {
      state.accounts.loading = true;
    },

    getHostUserAccountList_Success(
      state,
      action: PayloadAction<{
        settlementList: SettlementList[];
        count: number;
        totalSales: number;
        totalCount: number;
      }>,
    ) {
      state.accounts.settlementList = action.payload.settlementList;
      state.accounts.count = action.payload.count;
      state.accounts.totalCount = action.payload.totalCount;
      state.accounts.totalSales = action.payload.totalSales;
      state.accounts.loading = false;
    },
    getHostUserAccountList_Failure(state, _action: PayloadAction<undefined>) {
      state.hostComment.loading = false;
    },
  },
});

//* 관심 해피폴리오 불러오기 요청시
function* watchGetLikedList() {
  const { getLikedList_Request, getLikedList_Success, getLikedList_Failure } =
    happyfolioMeSlice.actions;

  yield takeEvery(getLikedList_Request, function* (action) {
    try {
      const { res, timeout } = yield race({
        res: call(getLikedHappyfolioListAPI, { page: action.payload.page }),
        timeout: delay(5000),
      });

      //? time out
      if (timeout) {
        throw new Error('좋아요 리스트 불러오기 timeout 에러');
      } else {
        yield put(getLikedList_Success(res.data));
      }
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getLikedList_Failure());
    }
  });
}

//* 구매한 해피폴리오 불러오기 요청시
function* watchGetPurchasedList() {
  const { getPurchasedList_Request, getPurchasedList_Success, getPurchasedList_Failure } =
    happyfolioActions;

  yield takeEvery(getPurchasedList_Request, function* (action) {
    try {
      const { res, timeout } = yield race({
        res: call(getPurchasedHappyfolioListAPI, { page: action.payload.page }),
        timeout: delay(5000),
      });
      //? time out
      if (timeout) {
        throw new Error('좋아요 리스트 불러오기 timeout 에러');
      } else {
        //? sucess
        yield put(getPurchasedList_Success(res.data));
      }
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getPurchasedList_Failure());
    }
  });
}

//* 나의 해피폴리오 댓글 불러오기 요청시
function* watchGetMyHappyfolioCommentList() {
  const {
    getMyHappyfolioComments_Request,
    getMyHappyfolioComments_Success,
    getMyHappyfolioComments_Failure,
  } = happyfolioActions;

  yield takeEvery(getMyHappyfolioComments_Request, function* (action) {
    try {
      const { res, timeout } = yield race({
        res: call(getMyHappyfolioCommentListAPI, { page: action.payload.page }),
        timeout: delay(5000),
      });
      //? time out
      if (timeout) {
        throw new Error('좋아요 리스트 불러오기 timeout 에러');
      } else {
        //? sucess
        yield put(getMyHappyfolioComments_Success(res.data));
      }
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getMyHappyfolioComments_Failure());
    }
  });
}

//* 나의 해피폴리오 현황보드 불러오기
function* watchGetMyHappyfolioBoardList() {
  const {
    getMyHappyfolioBoardList_Request,
    getMyHappyfolioBoardList_Success,
    getMyHappyfolioBoardList_Failure,
  } = happyfolioActions;

  yield takeEvery(getMyHappyfolioBoardList_Request, function* (action) {
    try {
      yield delay(300);
      const { res, timeout } = yield race({
        res: call(getMyHappyfolioBoardListAPI, { page: action.payload }),
        timeout: delay(30000),
      });
      //? time out
      if (timeout) {
        throw new Error('현황보드 리스트 불러오기 timeout 에러');
      } else {
        //? sucess
        yield put(getMyHappyfolioBoardList_Success(res.data));
      }
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getMyHappyfolioBoardList_Failure());
    }
  });
}

//* 나의 해피폴리오 멘토링 불러오기
function* watchGetHostUserCommentList() {
  const {
    getHostUserCommentList_Request,
    getHostUserCommentList_Success,
    getHostUserCommentList_Failure,
  } = happyfolioActions;

  yield takeEvery(getHostUserCommentList_Request, function* (action) {
    try {
      const { res, timeout } = yield race({
        res: call(getHostUserCommentListAPI, { page: action.payload }),
        timeout: delay(30000),
      });
      //? time out
      if (timeout) {
        throw new Error('멘토링 리스트 불러오기 timeout 에러');
      } else {
        //? sucess
        yield put(getHostUserCommentList_Success(res.data));
      }
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getHostUserCommentList_Failure());
    }
  });
}

//* 나의 해피폴리오 정산 내역 불러오기
function* watchGetHostUserAccountList() {
  const {
    getHostUserAccountList_Request,
    getHostUserAccountList_Success,
    getHostUserAccountList_Failure,
  } = happyfolioActions;

  yield takeEvery(getHostUserAccountList_Request, function* (action) {
    const { page, year, month } = action.payload;

    try {
      yield delay(300);
      const { res, timeout } = yield race({
        res: call(getHostUserAccountListAPI, { page, year, month }),
        timeout: delay(30000),
      });
      //? time out
      if (timeout) {
        throw new Error('정산 내역 불러오기 timeout 에러');
      } else {
        //? sucess
        yield put(getHostUserAccountList_Success(res.data));
      }
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getHostUserAccountList_Failure());
    }
  });
}

export const happyfolioMeSagas = [
  fork(watchGetLikedList),
  fork(watchGetPurchasedList),
  fork(watchGetMyHappyfolioCommentList),
  fork(watchGetMyHappyfolioBoardList),
  fork(watchGetHostUserCommentList),
  fork(watchGetHostUserAccountList),
];

export default happyfolioMeSlice;
