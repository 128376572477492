import { useCallback } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { UserInfoType } from '#types/data';
import axios from '../api/apiClient';
import { logout } from '../api/user';
import { deleteCookie, getCookie } from '../cookie';
import useGTM from './useGTM';
import { pick } from 'lodash';

const AUTH_METHOD = {
  google: 'google',
  facebook: 'facebook',
  naver: 'naver',
  local: 'email',
};

const useUserInfo = (options?: SWRConfiguration) => {
  const { pushGtmEvent } = useGTM();

  //* 로그인 시 백엔드에서 logged 쿠키를 심어 줌
  //* 로그아웃 시 백엔드에서 logged 쿠키 삭제
  //* client side에서 access_token에 접근 할 수 없기 때문에 (httpOnly 옵션이 true) 다음과 같이 해결

  const fetcher = useCallback(async (url: string) => {
    if (getCookie('logged') !== 'true') {
      throw new Error('unauthorized');
    }

    const provider = getCookie('signUpProvider');

    if (!!provider && window.location.href) {
      const newUrl = new URL(window.location.href);
      const domain = newUrl.hostname.replace('www.', '');

      pushGtmEvent({
        event: 'signup_done',
        auth_method: AUTH_METHOD[provider as keyof typeof AUTH_METHOD],
      });
      deleteCookie({ key: 'signUpProvider', domain });
    }

    try {
      const res = await axios.get(url);
      return res.data;
    } catch (err) {
      await logout();
      throw new Error(String(err));
    }
  }, []);

  const { data, mutate, error } = useSWR<UserInfoType>('/api/v2/me', fetcher, {
    errorRetryCount: 0,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    ...options,
    onSuccess: data => {
      if (typeof window === undefined) return;
      if (data) {
        window.userInfo = pick(data, ['id', 'profile.name']);
      } else {
        window.userInfo = null;
      }
    },
    onError() {
      if (typeof window === undefined) return;
      window.userInfo = null;
    },
  });

  return {
    userInfo: data,
    mutateUserInfo: mutate,
    isLoadingToGetUserInfo: !error && !data,
  };
};

export default useUserInfo;
