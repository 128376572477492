import axios from './apiClient';
import { SearchUserAPIResponse } from '#types/apiResponse';

export const logout = () => axios.post('/api/v2/auth/logout');

//* 단일 알림 읽음처리
export const readNotification = (notificationId: number) =>
  axios.patch(`/api/v2/me/notifications/${notificationId}`);

//* 단일 알림 지우기
export const deleteNotification = (notificationId: number) =>
  axios.delete(`/api/v2/me/notifications/${notificationId}`);

//* 모든 알림 지우기 + 모든 알림 읽음처리
export const deleteAllNotification = () => axios.delete('/api/v2/me/notifications/all');

export const editProfileImg = ({ imagePath }: { imagePath: string }) =>
  axios.patch('/api/v2/me/profiles/images', { imagePath });

export const editPassword = ({
  currentPassword,
  newPassword,
  newPasswordCheck,
}: {
  currentPassword: string;
  newPassword: string;
  newPasswordCheck: string;
}) =>
  axios.patch('/api/v2/me/profiles/password', {
    currentPassword,
    newPassword,
    newPasswordCheck,
  });

export const editName = (name: string) => axios.patch('/api/v2/me/profiles/name', { name });

export const editPhone = (phone: string) => axios.patch('/api/v2/me/profiles/phone', { phone });

export const editAdsAgree = (isAdsAgreed: boolean) =>
  axios.patch('/api/v2/me/profiles/ads-agree', { isAdsAgreed });

//* 유저 검색하기
export const searchUserAPI = (term: string) =>
  axios.get<SearchUserAPIResponse[]>(`api/v2/admins/users?email=${term}&limit=5&name=${term}`);
