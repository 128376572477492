import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, select, fork } from 'redux-saga/effects';
import { HappyfolioComment } from '#types/happyfolio';
import {
  deleteHappyfolioCommentAPI,
  editHappyfolioCommentAPI,
  getHappyfolioCommentAPI,
  GetHappyfolioCommentAPIType,
  writeHappyfolioCommentAPI,
} from '#lib/api/happyfolio';
import { sendErrorToSentry } from '#lib/error';
import { coreActions } from '../core';

export type happyfolioCommentState = {
  comments: HappyfolioComment[];
  loading: boolean;
  count: number;
  page: number;
};
const initialState: happyfolioCommentState = {
  loading: false,
  comments: [],
  count: 0,
  page: 1,
};

const happyfolioCommentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    getComment_Request(state, _action: PayloadAction<GetHappyfolioCommentAPIType>) {
      state.loading = true;
    },
    getComment_Success(
      state,
      action: PayloadAction<{ comments: HappyfolioComment[]; count: number; page: number }>,
    ) {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    },
    getComment_Failure(state) {
      state.loading = false;
    },
    writeComment_Request(_state, _action: PayloadAction<any>) {},
    writeComment_Failure(_state, _action) {},
    editComment_Request(_state, _action: PayloadAction<any>) {},
    editComment_Failure(_state, _action) {},
    deleteComment_Request(_state, _action: PayloadAction<any>) {},
    deleteComment_Failure(_state, _action) {},
  },
});

function* watchGetHappyfolioComment() {
  const { getComment_Request, getComment_Success, getComment_Failure } =
    happyfolioCommentSlice.actions;
  yield takeLatest(getComment_Request, function* (action) {
    try {
      const { data } = yield call(getHappyfolioCommentAPI, action.payload);
      yield put(getComment_Success({ ...data, page: Number(action.payload.page) }));
    } catch (err) {
      sendErrorToSentry(err);
      yield put(getComment_Failure());
    }
  });
}

function* watchWriteHappyfolioComment() {
  const { writeComment_Request, getComment_Request } = happyfolioCommentSlice.actions;

  yield takeLatest(writeComment_Request, function* (action) {
    try {
      yield call(writeHappyfolioCommentAPI, action.payload);
      yield put(
        getComment_Request({
          happyfolioId: action.payload.happyfolioId,
          page: '1',
          limit: '3',
        }),
      );
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: err.data || err.statusText || '댓글 작성에 실패했습니다.\n다시 시도해 주세요.',
          type: 'error',
        },
      });
    }
  });
}

function* watchEditHappyfolioComment() {
  const { editComment_Request, getComment_Request } = happyfolioCommentSlice.actions;
  yield takeLatest(editComment_Request, function* (action) {
    const commentPage: number = yield select(state => state.happyfolio.comment.page);
    try {
      yield call(editHappyfolioCommentAPI, action.payload);
      yield put(
        getComment_Request({
          happyfolioId: action.payload.happyfolioId,
          page: String(commentPage),
          limit: '3',
        }),
      );
    } catch (err) {
      sendErrorToSentry(err);
      yield put({
        type: coreActions.setToaster.type,
        payload: {
          visible: true,
          message: '댓글 수정에 실패했습니다.\n다시 시도해 주세요.',
          type: 'error',
        },
      });
    }
  });
}

function* watchDeleteHappyfolioComment() {
  const { deleteComment_Request, getComment_Request } = happyfolioCommentSlice.actions;
  const { setToaster } = coreActions;

  yield takeLatest(deleteComment_Request, function* (action) {
    const commentPage: number = yield select(state => state.happyfolio.comment.page);
    try {
      yield call(deleteHappyfolioCommentAPI, action.payload);
      yield put(
        getComment_Request({
          happyfolioId: action.payload.happyfolioId,
          limit: '10',
          page: String(commentPage),
        }),
      );
    } catch (err) {
      sendErrorToSentry(err);
      yield put(setToaster({ visible: true, message: err.data || err.statusText, type: 'error' }));
    }
  });
}

export const happyfolioCommentSagas = [
  fork(watchGetHappyfolioComment),
  fork(watchWriteHappyfolioComment),
  fork(watchEditHappyfolioComment),
  fork(watchDeleteHappyfolioComment),
];

export default happyfolioCommentSlice;
