import { combineReducers } from '@reduxjs/toolkit';
import { all } from 'redux-saga/effects';

import {
  Happyfolio,
  HappyfolioComment,
  HappyfolioPayload,
  LikedHappyfolioType,
  MyHappyfolioComment,
} from '#types/happyfolio';

import happyfolioMeSlice, { happyfolioMeSagas } from './me';
import registerHappyfolioSlice, { happyfolioRegisterSagas } from './register';
import happyfolioHomeSlice, { happyfolioHomeSagas } from './home';
import happyfolioDetailSlice, { happyfolioDetailSagas } from './detail';
import happyfolioCommentSlice, { happyfolioCommentSagas } from './comment';

export type HappyfolioState = {
  main: {
    list: { loading: boolean; happyfolios: Happyfolio[]; count: number };
    picked: { loading: boolean; happyfolios: Happyfolio[]; count: number };
    liked: { loading: boolean; happyfolios: Happyfolio[]; count: number };
    viewed: { loading: boolean; happyfolios: Happyfolio[]; count: number };
    responsed: { loading: boolean; happyfolios: Happyfolio[]; count: number };
  };
  detail: {
    happyfolio: Happyfolio | null;
    loading: boolean;
  };
  comment: {
    comments: HappyfolioComment[];
    loading: boolean;
    count: number;
    page: string;
  };
  me: {
    liked: {
      loading: boolean;
      list: LikedHappyfolioType[];
      count: number;
    };
    purchased: {
      loading: boolean;
      list: Happyfolio[];
      count: number;
    };
    comment: {
      loading: boolean;
      list: MyHappyfolioComment[];
      count: number;
    };
  };
  registerHappyfolio: {
    happyfolio: HappyfolioPayload;
    loading: boolean;
  };
};

export const happyfolioActions = {
  ...happyfolioHomeSlice.actions,
  ...happyfolioDetailSlice.actions,
  ...happyfolioCommentSlice.actions,
  ...happyfolioMeSlice.actions,
  ...registerHappyfolioSlice.actions,
};

export function* happyfolioSaga() {
  yield all([
    ...happyfolioHomeSagas,
    ...happyfolioDetailSagas,
    ...happyfolioCommentSagas,
    ...happyfolioMeSagas,
    ...happyfolioRegisterSagas,
  ]);
}

export const happyfolioReducer = combineReducers({
  happyfolioHome: happyfolioHomeSlice.reducer,
  detail: happyfolioDetailSlice.reducer,
  comment: happyfolioCommentSlice.reducer,
  me: happyfolioMeSlice.reducer,
  registerHappyfolio: registerHappyfolioSlice.reducer,
});

export default happyfolioReducer;
