import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  HappfoliosItemType,
  Happyfolio,
  HappyfolioPayInfo,
  HappyfolioReviewType,
} from '#types/happyfolio';
import {
  getHappyfolioListAPI,
  GetHappyfolioListQueryType,
  getHappyfolioReviewsAPI,
  GetHappyfolioReviewsAPIQueryType,
} from '#lib/api/happyfolio';
import { sendErrorToSentry } from '#lib/error';

export type HappyfolioDetaiiState = {
  happyfolioPayInfo: HappyfolioPayInfo | null;
  review: HappyfolioReviewType;
  recommandedHappyfolio: {
    recommandedHappyfolioList: HappfoliosItemType[];
    loading: boolean;
  };
  loading: boolean;
  isBeingPayment: boolean;
};

const initialState: HappyfolioDetaiiState = {
  happyfolioPayInfo: null,
  review: {
    reviews: [],
    count: 0,
    loading: false,
    page: '1',
  },
  recommandedHappyfolio: {
    recommandedHappyfolioList: [],
    loading: false,
  },
  loading: false,
  isBeingPayment: false,
};

const happyfolioDetailSlice = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    getRecommandedHappyfolioList_Request(
      state,
      _action: PayloadAction<GetHappyfolioListQueryType>,
    ) {
      state.recommandedHappyfolio.loading = true;
    },
    getRecommandedHappyfolioList_Success(state, action: PayloadAction<Happyfolio[]>) {
      state.recommandedHappyfolio.recommandedHappyfolioList = action.payload;
      state.recommandedHappyfolio.loading = false;
    },
    getHappyfolioReviews_Request(state, _action: PayloadAction<GetHappyfolioReviewsAPIQueryType>) {
      state.review.loading = true;
    },
    getHappyfolioReviews_Success(state, action: PayloadAction<HappyfolioReviewType>) {
      state.review.loading = false;
      state.review = { ...state.review, ...action.payload };
    },
    startPaymentProcess(state, _action: PayloadAction<undefined>) {
      if (state) {
        state.isBeingPayment = true;
      }
    },
    endPaymentProcess(state, _action: PayloadAction<undefined>) {
      if (state) {
        state.isBeingPayment = false;
      }
    },
    setHappyfolioPayInfo(state, action: PayloadAction<HappyfolioPayInfo>) {
      state.happyfolioPayInfo = action.payload;
    },
  },
});

function* watchGetRecommandedHappyfolioList() {
  const { getRecommandedHappyfolioList_Request, getRecommandedHappyfolioList_Success } =
    happyfolioDetailSlice.actions;
  yield takeLatest(getRecommandedHappyfolioList_Request, function* (action) {
    try {
      const { data } = yield call(getHappyfolioListAPI, action.payload);
      yield put(getRecommandedHappyfolioList_Success(data.happyfolios));
    } catch (err) {
      sendErrorToSentry(err);
    }
  });
}
function* watchGetHappyfolioReviews() {
  const { getHappyfolioReviews_Request, getHappyfolioReviews_Success } =
    happyfolioDetailSlice.actions;
  yield takeLatest(getHappyfolioReviews_Request, function* (action) {
    try {
      const { data } = yield call(getHappyfolioReviewsAPI, action.payload);
      yield put(getHappyfolioReviews_Success(data));
    } catch (err) {
      sendErrorToSentry(err);
    }
  });
}

export const happyfolioDetailSagas = [
  fork(watchGetRecommandedHappyfolioList),
  fork(watchGetHappyfolioReviews),
];

export default happyfolioDetailSlice;
