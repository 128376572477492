import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { GetHappyfolioOrderByListAPIResponse } from '#types/apiResponse';
import { HappfoliosItemType, ThemeListType } from '#types/happyfolio';
import { getHappyfolioThemeListAPI, GetHappyfolioListThemeQueryType } from '#lib/api/happyfolio';
import { sendErrorToSentry } from '#lib/error';

export type HappyfolioHomeState = {
  themedHappyfolioList: { happyfolios: ThemeListType[] };
  themedHappyfolio: {
    happyfolios: HappfoliosItemType[];
    themeId: number;
    themeTitle: string;
    themeDescription: string;
    loading: boolean;
    count: number;
    page: number;
    queries: GetHappyfolioListThemeQueryType | null;
  };
};

const initialState: HappyfolioHomeState = {
  themedHappyfolioList: {
    happyfolios: [],
  },
  themedHappyfolio: {
    themeId: 0,
    themeTitle: '',
    themeDescription: '',
    happyfolios: [],
    loading: true,
    count: 0,
    page: 1,
    queries: null,
  },
};

const happyfolioHomeSlice = createSlice({
  name: 'happyfolioHome',
  initialState,
  reducers: {
    //* =================================================================================== *//
    // 메인페이지에서 전체 리스트 페이지들을 불러올시

    getHappyfolioHomeList_Success(state, action: PayloadAction<ThemeListType[]>) {
      state.themedHappyfolioList.happyfolios = action.payload;
    },

    // orderBy로 하나의 List페이지들만 불러올시
    getHappyfolioOrderByList_Request(
      state,
      action: PayloadAction<GetHappyfolioListThemeQueryType>,
    ) {
      state.themedHappyfolio.loading = true;
      if (action.payload.page === '1') {
        state.themedHappyfolio.happyfolios = [];
      }
      state.themedHappyfolio.queries = action.payload;
    },
    getHappyfolioOrderByList_Success(
      state,
      action: PayloadAction<{ page: string; res: GetHappyfolioOrderByListAPIResponse }>,
    ) {
      if (action.payload.page === '1') {
        state.themedHappyfolio.happyfolios = action.payload.res.happyfolios;
      } else {
        state.themedHappyfolio.happyfolios = [
          ...state.themedHappyfolio.happyfolios,
          ...action.payload.res.happyfolios,
        ];
      }

      state.themedHappyfolio.count = action.payload.res.count;
      state.themedHappyfolio.themeId = action.payload.res.themeId;
      state.themedHappyfolio.themeTitle = action.payload.res.themeTitle;
      state.themedHappyfolio.themeDescription = action.payload.res.themeDescription;
      state.themedHappyfolio.loading = false;
      return state;
    },
    getHappyfolioOrderByList_Failure(state) {
      state.themedHappyfolio.loading = false;
      // state.detail.loading = false;
    },

    toggleHappyfolioLikedSuccess(state, action: PayloadAction<{ id: number }>) {
      const happyfolio = state.themedHappyfolio.happyfolios.find(
        recruitment => recruitment.id === action.payload.id,
      );
      if (happyfolio) {
        happyfolio.isLiked = !happyfolio.isLiked;
      }
    },
  },
});

//* 해피폴리오 불러오기 테마별 요청시
function* watchGetHappyfolioOrderByList() {
  const {
    getHappyfolioOrderByList_Request,
    getHappyfolioOrderByList_Success,
    getHappyfolioOrderByList_Failure,
  } = happyfolioHomeSlice.actions;

  yield takeLatest(getHappyfolioOrderByList_Request, function* (action) {
    try {
      const { data } = yield call(getHappyfolioThemeListAPI, action.payload);
      //? time out
      yield put(getHappyfolioOrderByList_Success({ page: String(action.payload.page), res: data }));
    } catch (err) {
      //? fialure
      sendErrorToSentry(err);
      yield put(getHappyfolioOrderByList_Failure());
    }
  });
}

export const happyfolioHomeSagas = [fork(watchGetHappyfolioOrderByList)];

export default happyfolioHomeSlice;
