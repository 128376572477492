export const setCookie = (name: string, value: string | number | Date, days?: any) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000 + 9 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const deleteCookie = ({ key, domain }: { key: string; domain?: string }) => {
  const isProd = process.env.NODE_ENV === 'production';

  if (isProd && domain) {
    const currentDomain = domain.includes('openknowl') ? '.openknowl.com' : '.miniintern.com';
    document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${currentDomain}`;
    return;
  }

  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
};

export const getDateTypeCookie = (name: string) => {
  if (getCookie(name) === null) {
    return new Date();
  }
  return new Date(String(getCookie(name)));
};
